

































































































































































import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import { loadWidget } from "@/utils/helpers";
import { IBreadcrumbs } from "@/types/types";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "Field is required"
});
@Component({
  name: "DetailsDrawer",
  components: {
    CircularProgressLoader: loadWidget("widgets/CircularProgressLoader"),
    ValidationProvider,
    ValidationObserver,
    SkeletonPreloader: loadWidget("widgets/SkeletonPreloader")
  }
})
export default class DetailsDrawer extends Vue {
  @Prop({ required: false, default: false }) drawerLoading!: boolean;
  @Prop({ required: true, default: false }) drawer!: boolean;
  @Prop({ required: false, default: () => [] }) breadcrumbs!: Array<
    IBreadcrumbs
  >;
  @Prop({ required: false }) updateLoader!: boolean;
  @Prop({ required: false }) category!: any;
  @Prop({ required: false, default: () => [] }) trackBy!: Array<any>;
  @Prop({ required: false, default: false }) temporary!: boolean;
  @Prop({ required: false, default: false }) isProduct!: boolean;
  show: boolean = false;
  details!: any;

  @Watch("category", { deep: true, immediate: true })
  onCategoryChange(newValue: any) {
    this.details = { ...newValue };
  }

  @Emit("closeDrawer")
  closeDrawer(value: boolean) {
    return value;
  }

  @Emit("categoryUpdate")
  updateCategory() {
    return {
      body: this.details,
      modulePath: "categories/categoryDialogUpdate"
    };
  }

  @Emit("navigateBack")
  moveBack() {
    return this.trackBy.pop();
  }
}
